.ant-carousel .slick-slider .slick-list .slick-track {
  padding-bottom: 20px;
}
.ant-carousel .slick-slider .slick-list .slick-track .slick-slide.slick-active.slick-center {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.ant-carousel .slick-dots li:not(last-child) {
  width: 15px;
  margin-right: 14px;
}
.ant-carousel .slick-dots li.slick-active {
  width: 15px;
}
.ant-carousel .slick-dots li button {
  background: #eaecf0;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  border: 0px;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #e12b25;
  width: 20px;
}
.ant-carousel .slick-dots.slick-dots-bottom {
  position: static;
  margin-top: 50px;
}
