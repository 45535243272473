.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 1rem;
  color: black;
}
.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  color: #e12b25;
}
.ant-pagination-item {
  /* height: auto; */
  border: none;
  background-color: transparent;
  font-family: 'Inter' sans-serif;
  font-family: 'Montserrat', sans-serif;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* line-height: 20px; */
}
.ant-pagination-item-active a {
  color: #e12b25;
  border-bottom: 2px solid #e12b25;
}
.ant-pagination-item a:hover {
  color: #e12b25;
}
