.ant-upload-picture-card-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.ant-upload-list.ant-upload-list-picture-card {
  flex-direction: row-reverse;
  justify-content: flex-end;
  display: flex;
}
.ant-upload-list-picture-card-container {
  min-width: max-content;
  flex-shrink: 0 0 auto;
}
.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  min-width: max-content;
  padding: 2rem;
}
