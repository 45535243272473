@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scrollbar-width: thin;
  scrollbar-color: #d0d5dd #eaecf0;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}
/* ::-webkit-scrollbar {
  display: none;
} */
*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: #d0d5dd;
}
p {
  margin-bottom: 0;
}
.rc-virtual-list .rc-virtual-list-scrollbar {
  display: block !important;
}
.rc-virtual-list-holder {
  overflow-y: scroll !important;
}

/* .ant-select-dropdown > ::-webkit-scrollbar-thumb {
  background-color: #d0d5dd;
} */

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* customize google button */
#buttonGoogle iframe {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

#buttonGoogle iframe #container {
  width: 100% !important;
  padding: 0 !important;
}

#buttonGoogle iframe #container > div {
  width: 100% !important;
  max-width: inherit !important;
  min-width: inherit !important;
}

@layer components {
  .display-xs-regular {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-medium {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-semibold {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .display-xs-bold {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .display-sm-regular {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-medium {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-semibold {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  .display-sm-bold {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }

  .display-md-regular {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-medium {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-semibold {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-md-bold {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-lg-regular {
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-medium {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-semibold {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-lg-bold {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
  }

  .display-xl-regular {
    font-weight: 400;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-medium {
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-semibold {
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-xl-bold {
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }

  .display-2xl-regular {
    font-weight: 400;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-medium {
    font-weight: 500;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-semibold {
    font-weight: 600;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .display-2xl-bold {
    font-weight: 700;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
  }

  .text-xs-regular {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-medium {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-semibold {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  .text-xs-bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }

  .text-sm-regular {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-medium {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-semibold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .text-sm-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .text-md-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .text-md-bold {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .text-lg-regular {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-medium {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-semibold {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .text-lg-bold {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }

  .text-xl-regular {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-medium {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-semibold {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .text-xl-bold {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  /* Effect Styles */
  .shadow-xs {
    background: #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  .shadow-sm {
    background: #d9d9d9;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  }

  .shadow-md {
    background: #d9d9d9;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }

  .shadow-lg {
    background: #d9d9d9;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  }

  .shadow-xl {
    background: #d9d9d9;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  }

  .shadow-2xl {
    background: #d9d9d9;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  }

  .shadow-3xl {
    background: #d9d9d9;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  }

  .bg-blur-sm {
    background: #d9d9d9;
    backdrop-filter: blur(4px);
  }

  .bg-blur-md {
    background: #d9d9d9;
    backdrop-filter: blur(8px);
  }

  .bg-blur-lg {
    background: #d9d9d9;
    backdrop-filter: blur(12px);
  }

  .bg-blur-xl {
    background: #d9d9d9;
    backdrop-filter: blur(20px);
  }

  /* ================================ */
  /* Navbar CSS */
  /* ================================ */
  .nav-item {
    @apply text-gray-500;
  }

  .nav-item:hover {
    @apply text-primary-500;
  }

  .button {
    height: 44px;
    width: 87px;
    border-radius: 8px;
    padding: 5px 12px;
  }

  .stepper__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .stepper__item:not(:last-child)::after {
    --size: 3rem;
    --spacing: 0.5rem;
    content: '';
    height: 0px;
    order: -1;
    width: calc(100% - var(--size) - calc(var(--spacing) * 2));
    left: calc(50% + calc(var(--size) / 2 + var(--spacing)));
    @apply border-b-0 border-dashed border-gray-200 relative top-[4.75rem];
  }

  .stepper__item:last-child::after {
    content: '';
    order: -1;
    height: 2px;
    background-color: transparent;
    @apply border-none;
  }

  .stepper__item::before {
    --size: 0.25rem;
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    margin: 1rem auto 0;
  }

  .drop-nav-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    width: 240px;
    border-radius: 4px;
    @apply border-gray-300 border;
  }

  .drop-nav-item {
    padding: 12px;
    display: flex;
    align-items: center;
    @apply text-gray-500;
  }

  .drop-nav-item:hover {
    border-radius: 3px;
    @apply text-gray-500 bg-gray-100;
  }
}

/* antd force override css  */
.ant-input-group .ant-input-group-addon {
  border-radius: 0.5rem 0 0 0.5rem;
}

.ant-input-group .ant-input {
  border-radius: 0 0.5rem 0.5rem 0;
}

.ant-switch.ant-switch-checked {
  background-color: #e12b25;
}

.ant-slider-dot-active {
  border-color: #e12b25;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle.ant-tooltip-open {
  border-color: #e12b25;
}

.ant-slider-handle,
.ant-slider-handle:focus,
.ant-slider-handle:hover {
  border: solid 2px #e12b25;
}

.ant-slider-handle,
.ant-slider:hover .ant-slider-track,
.ant-slider-track {
  background-color: #e12b25;
}

.ant-spin .ant-spin-dot-item {
  @apply bg-primary-500;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  @apply object-cover w-full h-full;
}

/* antd steps */
.ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon {
  @apply flex justify-center items-center;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  @apply ml-16 py-2 px-6;
}

.ant-steps-item-description {
  @apply w-full max-w-sm;
}

.ant-steps-item-title {
  @apply text-md-regular text-primary-700;
}

.ant-steps-item-icon {
  @apply p-5;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  @apply bg-gray-300;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  @apply bg-gray-50 border-gray-700;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  @apply text-gray-500 text-lg-medium;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  @apply text-gray-500;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  @apply bg-gray-50 border-primary-500;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  @apply text-primary-500 text-lg-medium;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  @apply text-primary-500;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  @apply bg-gray-300;
}

.ant-steps-item-finish .ant-steps-item-icon {
  @apply bg-primary-500 border-primary-500;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  @apply text-white;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  @apply bg-primary-500;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  @apply text-primary-700;
}

.ant-collapse.collapse-nav > .ant-collapse-item > .ant-collapse-header {
  @apply p-0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @apply rounded-md;
}

.ant-select-selection-item {
  @apply text-sm-medium text-gray-700;
}

.ant-select-arrow svg {
  @apply fill-gray-700;
}

.ant-upload-list-item-actions a span svg {
  @apply flex justify-center items-center;
}

/* Slick Carousel */
.slick-track {
  margin-left: 0;
  @apply flex gap-3 items-center;
}

.slick-track::before,
.slick-track::after {
  @apply content-none;
}

/* .banner-landing-page .ant-carousel {
  height: 100% !important;
}

.banner-landing-page .slick-slider {
  height: 100% !important;
}
.banner-landing-page .slick-list {
  height: 100% !important;
}
.banner-landing-page .slick-track {
  height: 100% !important;
}

.banner-landing-page .slick-slide {
  width: 100% !important;
  height: 100% !important;
}
.banner-landing-page .slick-slide>div {
  width: 100% !important;
  height: 100% !important;
} */

.banner-landing-page .ant-carousel .slick-slider .slick-list .slick-track{
  padding-bottom: 0 !important;
}

.slick-dots li button:before {
  @apply text-xl text-gray-400;
}

.slick-dots li.slick-active button:before {
  @apply text-primary-500;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  padding: 0px !important;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  padding: 0px !important;
}

.sign-up-content {
  border-radius: 2rem 0 0 2rem;
}

/* ANTD DatePicker Range */
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #e12b25;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #e12b25;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .and-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .and-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #f6937a;
  color: #cbe6ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: #fde2d3;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #fde2d3;
}

.custom-table-limit .ant-select-selector {
  border-radius: 6px !important;
  height: 36px !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: #d0d5dd !important;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .ant-calendar-range {
    width: 320px;
  }

  .ant-calendar-range-part {
    width: 100%;
  }
}
