@import '~antd/dist/antd.css';

/* Reset HTML */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter' serif;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}
/* Class */
.text-icon-center {
  display: grid;
  grid-template-columns: auto auto;
  grid-column: 30px 1fr;
  gap: 10px;
}
img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.quill .ql-container .ql-editor i {
  /* font-style: 'italic' !important; */
  @apply !italic;
}
