.radio-input {
  display: none;
}
.radio-label {
  background-color: #eaecf0;
  border-radius: 4px;
  padding-inline: 16px;
  padding-block: 8px;
  cursor: pointer;
  color: #667085;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.radio-label {
  @apply lg:hover:bg-[#fde2d3] lg:hover:text-[#a21226];
}

.radio-input:checked + .radio-label {
  background-color: #fde2d3;
  color: #a21226;
}

.radio {
  display: flex;
  gap: 16px;
  justify-content: center;
  @apply gap-2 md:gap-4;
}
