.ant-menu {
  background-color: #f9fafb;
  border: 0px;
  font-family: 'Inter' sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  color: #344054;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.ant-menu-item {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.ant-menu-light .ant-menu-item:hover {
  color: #e12b25;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: black;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #e12b25;
  background-color: #feece2;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #e12b25;
}
